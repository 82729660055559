<template>
  <div class="data-table">
    <BaseTable :data="displayData">
      <EmptyBlock slot="empty" />
      <BaseElTableColumn label="優惠券名稱" prop="name" align="center" />
      <BaseElTableColumn label="優惠券規則 Uid" prop="pospalEcouponId" align="center" />
      <BaseElTableColumn label="有效週期" prop="dateRange" align="center" width="180px" />
      <BaseElTableColumn label="發放後幾天生效" prop="beginDays" align="center" />
      <BaseElTableColumn label="發放後有效天數" prop="avaliableDays" align="center" />
      <BaseElTableColumn label="操作" fixed="right" width="110" align="center">
        <template slot-scope="scope">
          <TableEditBtnGroup
            hideDelete
            editBtn="詳情"
            @edit="onRowEdit(scope.row)"
          />
        </template>
      </BaseElTableColumn>
    </BaseTable>

    <PospalCouponDetail
      v-if="modal.detail"
      :data="selectRow"
      @close="modal.detail = false"
    />
  </div>
</template>

<script>
import { defineComponent, computed, ref, reactive } from 'vue'
import EmptyBlock from '@/components/EmptyBlock.vue'
import PospalCouponDetail from './PospalCouponDetail.vue'
import { useTable } from '@/use/table'
import { get, map, isNumber } from 'lodash'
import { formatDate } from '@/utils/date'

export default defineComponent({
  name: 'PospalCouponTable',
  components: { EmptyBlock, PospalCouponDetail },
  props: {
    tableData: { type: Array, defalt: () => [] },
  },
  emits: ['refresh'],
  setup (props, { emit }) {
    const { shopId } = useTable()
    const selectRow = ref(null)
    const modal = reactive({
      detail: false,
    })

    const displayData = computed(() => {
      const data = props.tableData

      return map(data, (item) => {
        const startDate = get(item, 'extraSetting.posPalConfig.startDate')
        const endDate = get(item, 'extraSetting.posPalConfig.endDate')
        const beginDays = get(item, 'extraSetting.posPalConfig.beginDays')
        const avaliableDays = get(item, 'extraSetting.posPalConfig.avaliableDays')
        return {
          id: get(item, 'id'),
          name: get(item, 'name') || '-',
          pospalEcouponId: get(item, 'extraStatus.pospalEcouponId') || '-',
          dateRange: startDate || endDate ? `${formatDate(startDate)} ~ ${formatDate(endDate)}` : '-',
          beginDays: isNumber(beginDays) ? beginDays : '-',
          avaliableDays: isNumber(avaliableDays) ? avaliableDays : '-',
        }
      })
    })

    const onRowEdit = (row) => {
      console.log(row)
      selectRow.value = row
      modal.detail = true
    }

    const onRowDelete = (row) => {
      selectRow.value = row
      modal.delete = true
    }

    return {
      modal,
      displayData,
      onRowEdit,
      selectRow,
      onRowDelete,
    }
  },
})
</script>
